/**
 * Is required to add `passive: true` to all event listeners.
 * This is because react-elastic-carousel doesnt use `passive: true`
 * @deprecated because it renders dropdown selects unusable
 */
// eslint-disable-next-line @typescript-eslint/no-require-imports
// require("default-passive-events")
import "../../styles/globals.css"
import { ApiGatewayDegeniaRestProvider, CustomerProvider } from "../../src"
import {
	ApiGatewayRestProvider,
	ApiGatewayWebsocketProvider,
	AppConfigProvider,
	AppRouterProvider,
	AuthProvider,
	CmsProvider,
	FileProvider,
	ModalContainer,
	ThemeProvider,
	Toasts, TrackingProvider
} from "@teamparallax/react-components"
import { ERoleCapabilities } from "@teamparallax/common-libraries/lib/rbac/enum"
import {
	EUserRole, IUserRole, IUserWithRolesAndToken
} from "@teamparallax/api-gateway-rest-api"
import { appWithTranslation } from "next-i18next"
import { rbacCheckAllowed } from "@teamparallax/common-libraries/lib/rbac"
import { useRouter } from "next/router"
import DynamicImport from "../../next-components/dynamic-import"
import React, { FC } from "react"
import getConfig from "next/config"
import nextI18nNextConfig from "../../next-i18next.config"
import type { AppProps } from "next/app"
/**
 * Waiting for suspense working for SSR, then we can supply default theme providers
 */
const App: FC<AppProps> = ({
	// eslint-disable-next-line @typescript-eslint/naming-convention
	Component, pageProps
}) => {
	const router = useRouter()
	let doesUseWebsockets = false
	const rootUrl = "/"
	const portalUrl = "/portal"
	const explanationForDegeniaCustomers = "/portal/gesellschaften-und-produkte/erklaerung"
	const SignInUrl = "/sign-in"
	const meineDegeniaUrl = "/meine-degenia"
	if (
		router.asPath.startsWith(portalUrl)
		|| router.asPath.startsWith(SignInUrl)
		|| router.route.startsWith(meineDegeniaUrl)
	) {
		doesUseWebsockets = true
	}
	const logoutCallback = async (): Promise<void> => {
		if (router.route.startsWith(portalUrl) || router.route.startsWith(meineDegeniaUrl)) {
			await router.push(rootUrl)
		}
	}
	const loginCallback = async (profile: IUserWithRolesAndToken): Promise<void> => {
		const {
			roles
		} = profile
		const isAllowedToLoginToPortal = await rbacCheckAllowed(
			roles,
			ERoleCapabilities.frontEndLoginBrokerBackend
		)
		const isAllowedToLoginToCustomerBackend = await rbacCheckAllowed(
			roles,
			ERoleCapabilities.frontEndLoginCustomerBackend
		)
		if (isAllowedToLoginToPortal) {
			const degeniaCustomer = roles.find((
				userRole: IUserRole
			) => userRole.roleName === EUserRole.DegeniaCustomerCorporation)
			/**
			 * Is already in portal
			 */
			if (!router.route.startsWith(portalUrl)) {
				if (degeniaCustomer) {
					await router.push(explanationForDegeniaCustomers)
				}
				/**
				 * @TODO
				 * If the user is on any site except the portal site
				 * he gets automatically forwarded to portal
				 * this is rather bad. this is because of the login
				 * screen which is no extra page, but should be
				 */
				else if (!router.route.startsWith("/news")) {
					await router.push(portalUrl)
				}
			}
		}
		else if (isAllowedToLoginToCustomerBackend) {
			await router.push(meineDegeniaUrl)
		}
	}
	const allowedToBeHereCallback = async (profile: IUserWithRolesAndToken): Promise<void> => {
		const {
			roles
		} = profile
		const isAllowedToBeInPortal = await rbacCheckAllowed(
			roles,
			ERoleCapabilities.frontEndLoginBrokerBackend
		)
		const isAllowedToBeInCustomerBackend = await rbacCheckAllowed(
			roles,
			ERoleCapabilities.frontEndLoginCustomerBackend
		)
		if (!isAllowedToBeInPortal && router.route.startsWith(portalUrl)) {
			console.warn("user not allowed to be in portal")
			await router.push(rootUrl)
		}
		else if (!isAllowedToBeInCustomerBackend && router.route.startsWith(meineDegeniaUrl)) {
			console.warn("user not allowed to be in customer backend")
			await router.push(rootUrl)
		}
	}
	/* eslint-disable-next-line @typescript-eslint/no-explicit-any  */
	const getLayout = (Component as any).getLayout ?? ((page:FC):FC => page)
	const config = getConfig().publicRuntimeConfig
	return (
		<TrackingProvider
			fbPixelId={ config.facebookPixelId }
			gtmId={ config.googleTagManagerId }
			isProd={ config.isProd }
			matomoSiteId={ config.matomoSiteId }
			matomoUrl={ config.matomoUrl }
		>
			<AppConfigProvider
				config={ {
					...getConfig().publicRuntimeConfig,
					doesNotUseWebsockets: !doesUseWebsockets
				} }
			>
				<Toasts/>
				<ApiGatewayRestProvider>
					<ApiGatewayDegeniaRestProvider>
						<ApiGatewayWebsocketProvider>
							<AppRouterProvider>
								<AuthProvider
									allowedToBeHereCallback={ allowedToBeHereCallback }
									loginCallback={ loginCallback }
									logoutCallback={ logoutCallback }
								>
									<FileProvider>
										<CmsProvider>
											<DynamicImport>
												<ThemeProvider>
													<CustomerProvider>
														{getLayout(<Component { ...pageProps }/>)}
														<ModalContainer/>
													</CustomerProvider>
												</ThemeProvider>
											</DynamicImport>
										</CmsProvider>
									</FileProvider>
								</AuthProvider>
							</AppRouterProvider>
						</ApiGatewayWebsocketProvider>
					</ApiGatewayDegeniaRestProvider>
				</ApiGatewayRestProvider>
			</AppConfigProvider>
		</TrackingProvider>
	)
}
export default appWithTranslation(App as never, nextI18nNextConfig)
/**
 * Wait for `getStaticProps` to be available:
 * @link https://github.com/vercel/next.js/discussions/10949
 */
/* AdminPortal.getInitialProps = async (appCtx) => {
	const { Component, ctx } = appCtx
	let pageProps = {}
	const isServer = false
	if (Component.hasStaticProps && isServer ) {
		return { is404: false }
	} else {
		if (Component.getInitialProps) {
			pageProps = (await (Component.getInitialProps(ctx))) || {}
		}
		const theme = await CThemeLoader[defaultTheme]()
		defaultThemeProvider = theme.default
		return {
			pageProps: {
				defaultThemeProvider,
				...pageProps
			},
			is404: !!pageProps["is404"],
		}
	}
} */